.padding{
    padding:15px;
    /* font-family: Arial, Helvetica, sans-serif; */
}
.paddingTop{
    padding-top: 15px;
    font-size: xx-large;
}
.health{
    color: #2b8e33;
    padding: 15px;
    padding-top: 0;
    font-size: larger;
}
.block-font{
    font-size: large;
}
#aboutHeader{
    margin-top: 14px;
    padding-bottom: 0;
    font-size: 30px;
}
.text-green{
    color: #2b8e33;
}
.block-size-img{
    width: 800px;
    height: 533px;
}
.block-size{
    width: 700px;;
}
@media (max-width: 575.98px) {
    .block-font{
        font-size: medium;
    }
}