@import url(https://fonts.googleapis.com/css2?family=Piedra&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Acme&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Piedra&display=swap);
.no-padding {
    overflow-x: hidden;
  }
body{
  font-family: Arial, Helvetica, sans-serif;
}
  .AnyClassForContainer {
  position: fixed;
  right: -100px;
  bottom: 150px;
  transition: right 0.5s;
  cursor: pointer;
  opacity: 0.5;
  background-color: white;
  font-size: 20px;
  padding: 10px;
  opacity: 0.9;
	 box-shadow: 1px 1px 6px 0 grey;
}

.AnyClassForTransition {
  right: 20px;
}
/* @import "~video-react/styles/scss/video-react"; */



.back{
    height: 8em;
}
/* .border-5 {
    border-width:3px !important;
    border-color: peachpuff !important;
} */
.padding{
    padding-top: 5px;
    padding-bottom: 5px;
}
.green{
    background-color:rgba(56, 167, 0, 0.7);
    color:black;
    width:200px;
}
.top-margin{
    top:10px;
}
#logoWrapper{
    width: 100px;
    height: 100px;
}
#appointmentBtn{
    width: 330px;
    height: 80px;
    margin: 25px;
    background-color: rgb(160, 200, 61);
    border-radius: 10px;
}
.app-shadow{
    box-shadow: 1px 1px 6px 0 grey;
}
#appointmentBtn:hover{
    background-color: rgb(57, 139, 55);
}
#appointmentBtn p{
    font-size: 20px;
    color: #fff;
    padding: 23px;
}





.backGround {
  background-color: white;
}

.navbar.center .navbar-inner {
  text-align: center;
}

.navbar.center .navbar-inner .nav {
  display: inline-block;
  float: none;
  vertical-align: top;
}

.onlyBar span {
  display: inline-block;
  color: white;
  opacity: 0.8;
  text-align: center;
  letter-spacing: -0.5px;
  font-size: 1rem;
}

.onlyBar span:after {
  content: '';
  width: 0px;
  height: 1px;
  display: block;
  background: lightblue;
  transition: 300ms;
}
.onlyBar span:hover {
  color: white;
  opacity: 1;
}
.onlyBar span:hover:after {
  width: 100%;
  /* color:white */
}

.margin-right {
  margin-right: 8px;
  /* opacity: 0.5; */
}
@media (max-width: 1230px) {
  .margin-right {
    margin-right: 0px;
  }
  .onlyBar span {
    font-size: 0.9rem;
  }
}
.grad-grad {
  background: rgb(238, 174, 202);
  background: radial-gradient(
    circle,
    rgba(238, 174, 202, 1) 0%,
    rgba(148, 187, 233, 1) 100%
  );
  opacity: 0.85;
}

.navBarContact {
  display: flex;
  align-items: center;
}
.navBarContact p {
  font-size: 0.8rem;
  padding-left: 10px;
  letter-spacing: -0.5px;
}

footer{
    left: 0px;
    bottom: 0px;
    width: 100%;
    height: 4.2em;
}
.shrink-3{
    right:50px;
    position: relative;

}
.shrink-2{
    position: relative;
}
.media_font{
    font-size: 15px;
}
@media (max-width:944px) and (min-width:767px){
    .media_font{
        font-size: 11.5px;
    }
}
.shrink-1{
    left:50px;
    position: relative;
}

p{
    margin: 0 auto;
    padding-top: 5px;
    padding-bottom: 5px;
}
.grad-grad1{
    background: rgb(238,174,202);
background: radial-gradient(circle, rgba(238,174,202,1) 0%, rgba(148,187,233,1) 100%);
}



.top{
    margin-top:15px;
}
.bottom{
    margin-bottom: 15px;
}
.top-h{
    margin-top:25px;
}
.fontSize25{
    font-size: normal;
}
.fontSize225{
    font-size: 1.09em;
}
@media (max-width: 575.98px) {
    .fontSize25{
        font-size: larger;
    }
    .fontSize225{
        font-size: medium;
    }
}


.marginT {
  margin-bottom: 20px;
}

.marginTB {
  margin-bottom: 20px;
  margin-top: 20px;
  color: #2b8e33;
}
.font {
  font-size: smaller;
}
@media (max-width: 720px) {
  .font {
    text-align: left;
  }
  .marginTB {
    text-align: left;
  }
}
.width-95 {
  width: 98%;
}
.fliping-img {
  transform: scaleX(-1);
}
.padding-15 {
  padding-bottom: 15px;
  padding-inline: 15px;
}

.padding{
    padding:15px;
    /* font-family: Arial, Helvetica, sans-serif; */
}
.paddingTop{
    padding-top: 15px;
    font-size: xx-large;
}
.health{
    color: #2b8e33;
    padding: 15px;
    padding-top: 0;
    font-size: larger;
}
.block-font{
    font-size: large;
}
#aboutHeader{
    margin-top: 14px;
    padding-bottom: 0;
    font-size: 30px;
}
.text-green{
    color: #2b8e33;
}
.block-size-img{
    width: 800px;
    height: 533px;
}
.block-size{
    width: 700px;;
}
@media (max-width: 575.98px) {
    .block-font{
        font-size: medium;
    }
}

h4{
    font-weight: bold;
}
.border-bottom{
    padding-bottom: 5px;
}

.width{
    width: 250px;
}
@media (max-width: 575.98px) {
    .width{
        width: 250px;
        padding: 5px;
        margin:5px;
    }
}
/* .margin-left{
    margin-left: 22px;
} */
.marginBottom{
    margin-bottom: 20px;
}
.width {
  width: 250px;
}
@media (max-width: 575.98px) {
  .width {
    width: 250px;
    padding: 5px;
    margin: 5px;
  }
}

.text {
  font-size: large;
  font-weight: 100;
  padding-inline: 15px;
  padding-block: 10px;
}
.text-green {
  color: #2b8e33;
}

ul {
  margin-bottom: 0rem;
}
li::marker {
  color: #2b8e33;
}

