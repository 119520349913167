/* @import "~video-react/styles/scss/video-react"; */
@import url('https://fonts.googleapis.com/css2?family=Piedra&display=swap');



.back{
    height: 8em;
}
/* .border-5 {
    border-width:3px !important;
    border-color: peachpuff !important;
} */
.padding{
    padding-top: 5px;
    padding-bottom: 5px;
}
.green{
    background-color:rgba(56, 167, 0, 0.7);
    color:black;
    width:200px;
}
.top-margin{
    top:10px;
}
#logoWrapper{
    width: 100px;
    height: 100px;
}
#appointmentBtn{
    width: 330px;
    height: 80px;
    margin: 25px;
    background-color: rgb(160, 200, 61);
    border-radius: 10px;
}
.app-shadow{
    -webkit-box-shadow: 0 8px 6px -6px black;
    -moz-box-shadow: 0 8px 6px -6px black;
         box-shadow: 1px 1px 6px 0 grey;
}
#appointmentBtn:hover{
    background-color: rgb(57, 139, 55);
}
#appointmentBtn p{
    font-size: 20px;
    color: #fff;
    padding: 23px;
}




