footer{
    left: 0px;
    bottom: 0px;
    width: 100%;
    height: 4.2em;
}
.shrink-3{
    right:50px;
    position: relative;

}
.shrink-2{
    position: relative;
}
.media_font{
    font-size: 15px;
}
@media (max-width:944px) and (min-width:767px){
    .media_font{
        font-size: 11.5px;
    }
}
.shrink-1{
    left:50px;
    position: relative;
}

p{
    margin: 0 auto;
    padding-top: 5px;
    padding-bottom: 5px;
}
.grad-grad1{
    background: rgb(238,174,202);
background: radial-gradient(circle, rgba(238,174,202,1) 0%, rgba(148,187,233,1) 100%);
}

