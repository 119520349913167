.no-padding {
    overflow-x: hidden;
  }
body{
  font-family: Arial, Helvetica, sans-serif;
}
  .AnyClassForContainer {
  position: fixed;
  right: -100px;
  bottom: 150px;
  transition: right 0.5s;
  cursor: pointer;
  opacity: 0.5;
  background-color: white;
  font-size: 20px;
  padding: 10px;
  opacity: 0.9;
  -webkit-box-shadow: 0 8px 6px -6px black;
	   -moz-box-shadow: 0 8px 6px -6px black;
	        box-shadow: 1px 1px 6px 0 grey;
}

.AnyClassForTransition {
  right: 20px;
}