.backGround {
  background-color: white;
}

.navbar.center .navbar-inner {
  text-align: center;
}

.navbar.center .navbar-inner .nav {
  display: inline-block;
  float: none;
  vertical-align: top;
}

.onlyBar span {
  display: inline-block;
  color: white;
  opacity: 0.8;
  text-align: center;
  letter-spacing: -0.5px;
  font-size: 1rem;
}

.onlyBar span:after {
  content: '';
  width: 0px;
  height: 1px;
  display: block;
  background: lightblue;
  transition: 300ms;
}
.onlyBar span:hover {
  color: white;
  opacity: 1;
}
.onlyBar span:hover:after {
  width: 100%;
  /* color:white */
}

.margin-right {
  margin-right: 8px;
  /* opacity: 0.5; */
}
@media (max-width: 1230px) {
  .margin-right {
    margin-right: 0px;
  }
  .onlyBar span {
    font-size: 0.9rem;
  }
}
.grad-grad {
  background: rgb(238, 174, 202);
  background: radial-gradient(
    circle,
    rgba(238, 174, 202, 1) 0%,
    rgba(148, 187, 233, 1) 100%
  );
  opacity: 0.85;
}

.navBarContact {
  display: flex;
  align-items: center;
}
.navBarContact p {
  font-size: 0.8rem;
  padding-left: 10px;
  letter-spacing: -0.5px;
}
