
@import url('https://fonts.googleapis.com/css2?family=Acme&display=swap');
.top{
    margin-top:15px;
}
.bottom{
    margin-bottom: 15px;
}
.top-h{
    margin-top:25px;
}
.fontSize25{
    font-size: normal;
}
.fontSize225{
    font-size: 1.09em;
}
@media (max-width: 575.98px) {
    .fontSize25{
        font-size: larger;
    }
    .fontSize225{
        font-size: medium;
    }
}

