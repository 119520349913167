.marginT {
  margin-bottom: 20px;
}
@import url('https://fonts.googleapis.com/css2?family=Piedra&display=swap');

.marginTB {
  margin-bottom: 20px;
  margin-top: 20px;
  color: #2b8e33;
}
.font {
  font-size: smaller;
}
@media (max-width: 720px) {
  .font {
    text-align: left;
  }
  .marginTB {
    text-align: left;
  }
}
.width-95 {
  width: 98%;
}
.fliping-img {
  -webkit-transform: scaleX(-1);
  -moz-transform: scaleX(-1);
  -o-transform: scaleX(-1);
  transform: scaleX(-1);
}
.padding-15 {
  padding-bottom: 15px;
  padding-inline: 15px;
}
