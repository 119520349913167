.width {
  width: 250px;
}
@media (max-width: 575.98px) {
  .width {
    width: 250px;
    padding: 5px;
    margin: 5px;
  }
}

.text {
  font-size: large;
  font-weight: 100;
  padding-inline: 15px;
  padding-block: 10px;
}
.text-green {
  color: #2b8e33;
}

ul {
  margin-bottom: 0rem;
}
li::marker {
  color: #2b8e33;
}
